import { Component, OnInit } from '@angular/core';
import { AuthService } from '../login/shared/services/auth.service';
import { Router } from '@angular/router';

declare const $: any;
declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  { path: '/configura', title: 'Configurações', icon: 'build', class: '' },
];

const CadastroRoutes: RouteInfo[] = [
  { path: '/carac', title: 'Clientes', icon: 'blur_circular', class: '' },
  { path: '/usuario', title: 'Usuários', icon: 'account_circle', class: '' },
]

const RelatRoutes: RouteInfo[] = [
  { path: '/email', title: 'Resumo AMPM', icon: 'view_in_ar', class: '' },
]

const QualidadeRoutes: RouteInfo[] = [
  { path: '/produtoAndamento', title: 'Produtos em Andamento', icon: 'card_travel', class: '' },
  { path: '/loteAgrupa', title: 'Relação Lotes', icon: 'border_inner', class: '' },
  { path: '/espec', title: 'Especificar Lotes', icon: 'center_focus_strong', class: '' },
  { path: '/histor', title: 'Hist Revisões', icon: 'album', class: '' },
]

const AtividadeRoutes: RouteInfo[] = [
  { path: '/tarefa', title: 'Tarefas', icon: 'checkbox', class: '' },,

]

const MovimentoRoutes: RouteInfo[] = [
  { path: '/contasReceber', title: 'Contas a Receber', icon: 'home', class: '' },
  { path: '/contasPagar', title: 'Contas a Pagar', icon: 'fullscreen_exit', class: '' },
  { path: '/banco', title: 'Extratos', icon: 'fullscreen_exit', class: '' },
]

const SaudeRoutes: RouteInfo[] = [
  { path: '/peso', title: 'Peso', icon: 'favorite', class: '' },
  { path: '/consulta', title: 'Consultas', icon: 'settings_accessibility', class: '' },
  { path: '/meta', title: 'Metas', icon: 'arrow_circle_right', class: '' },
]

const CulturaRoutes: RouteInfo[] = [
  { path: '/livro', title: 'Livros', icon: 'token', class: '' },
  { path: '/diario', title: 'Diário', icon: 'move_up', class: '' },
  { path: '/cidade', title: 'Cidade', icon: 'move_up', class: '' }
]


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  relatItems: any[];
  cadastroItems: any[];
  qualidadeItens: any[];
  atividadeItems: any[];
  movimentoItens: any[];
  saudeItens: any[];
  culturaItens: any[];
  xcPerfil = JSON.parse(localStorage.getItem('user'));
  id: string = 'navigation';
  colapsed: boolean = false;

  constructor(
    public authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.relatItems = RelatRoutes.filter(relatItems => relatItems);
    this.cadastroItems = CadastroRoutes.filter(cadastroItems => cadastroItems);
    this.atividadeItems = AtividadeRoutes.filter(atividadeItems => atividadeItems);
    this.qualidadeItens = QualidadeRoutes.filter(qualidadeItens => qualidadeItens);
    this.movimentoItens = MovimentoRoutes.filter(movimentoItens => movimentoItens);
    this.saudeItens = SaudeRoutes.filter(saudeItens => saudeItens);
    this.culturaItens = CulturaRoutes.filter(culturaItens => culturaItens);

  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };
}
