/* app.component.ts */
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CommonModule } from '@angular/common';
import { CanvasJS, CanvasJSAngularChartsModule } from '@canvasjs/angular-charts';

@Component({
	selector: 'app-line-chart',
	imports: [RouterOutlet, CommonModule, CanvasJSAngularChartsModule],
	templateUrl: './chart.component.html',
	styleUrls: ['./chart.component.css'],
})
export class ChartComponent implements OnInit, OnChanges {
	chart: any;
	@Input() data = [];
	@Input() titulo = "";

	dataPoints = [];
	chartOptions;

	ngOnInit(): void {
	}

	ngOnChanges(changes: SimpleChanges): void {
		changes.data.currentValue.forEach(d => {
			this.dataPoints.push({
				type: "line",
				xValueType: "dateTime",
				dataPoints: [...d],
			});
		});
		console.log(this.dataPoints);
		//if (this.dataPoints.length > 0) {
		this.chartOptions = {
			theme: "light2",
			animationEnabled: true,
			zoomEnabled: true,
			title: {
				text: this.titulo
			},
			axisY: {
				title: ""
			},
			axisX: {
				title: "Meses",
				interval: 1,
				valueFormatString: "MMM",

				// labelFormatter: function (e) {
				// 	return CanvasJS.formatDate(e.value, "MMM");
				// }
			},
			data: this.dataPoints
		}
		//}
	}


}          