import { Injectable } from '@angular/core';
import { Http, Response } from '@angular/http';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'app/components/confirm-dialog/confirm-dialog.component';
import { Observable, Subscription, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import * as XLSX from 'xlsx';



@Injectable({
  providedIn: 'root'
})

export class funcsService {

  constructor(
    private _http: Http,
    private http: HttpClient,
    public dialog: MatDialog,
  ) { }

  execPar(_url, obj) {
    let url = '';
    const dstUrla = ['10.3.0.49:900'];
    url = `http://${dstUrla}/${_url}`
    $.ajaxSetup({ async: false });
    $.post(url, obj);
  }

  buscaPrt(_url, obj) {
    let url = 'http://195.35.18.227:3164/' + _url;

    return this._http.post(url, obj).pipe(map((response: Response) => { return response.json() }))
  }

  buscaPcfa(_url, obj) {
    let url = '';
    const dstUrla = ['10.3.0.49:886'];

    url = `http://${dstUrla}/${_url}`

    return this._http.post(url, obj).pipe(map((response: Response) => response.json()));

  }

  buscaPcfb(_url, obj) {
    let url = '';
    const dstUrla = ['10.3.0.49:887'];

    url = `http://${dstUrla}/${_url}`

    return this._http.post(url, obj).pipe(map((response: Response) => response.json()));

  }

  buscaPcfc(_url, obj) {
    let url = '';
    const dstUrla = ['10.3.0.49:888'];

    url = `http://${dstUrla}/${_url}`

    return this._http.post(url, obj).pipe(map((response: Response) => response.json()));

  }

  prodOP(obj) {
    let url = '';

    url = `http://10.3.0.204:8095/REST/AJUST_EMP`

    return this._http.put(url, obj).pipe(map((response: Response) => response.json()));

  }

  prodLote(obj) {
    let url = '';

    url = `http://10.3.0.204:8095/rest/APILOTES/APILOTEESP`

    return this._http.post(url, obj).pipe(map((response: Response) => response.json()));

  }

  buscaPar(_url) {
    let url = '';
    const dstUrla = ['10.3.0.49:900'];

    url = `http://${dstUrla}/${_url}`

    return this._http.get(url).pipe(map((response: Response) => response.json()));

  }

  execProd(_url, obj) {
    let url = '';
    const dstUrla = ['10.3.0.49:885'];
    url = `http://${dstUrla}/${_url}`

    $.ajaxSetup({ async: false });

    $.post(url, obj);
  }


  toHHMMSS = (secs) => {
    let horaRet
    var sec_num = parseInt(secs, 10)
    var hours = Math.floor(sec_num / 3600)
    var minutes = Math.floor(sec_num / 60) % 60
    var seconds = sec_num % 60

    horaRet = [hours, minutes, seconds]
      .map(v => v < 10 ? "0" + v : v)
      .filter((v, i) => v !== "00" || i > 0)
      .join(":")

    return horaRet.length === 2 ? '00:00:' + horaRet : horaRet.length === 5 ? '00:' + horaRet : horaRet

  }

  viraHoras = (secs) => {
    var sec_num = parseInt(secs, 12)
    var hours = Math.floor(sec_num / 3600) === 0 ? '000' : Math.floor(sec_num / 3600)
    var minutes = Math.floor(sec_num / 60) % 60
    var seconds = sec_num % 60

    return [hours, minutes, seconds]
      .map(v => v < 10 ? "0" + v : v)
      .filter((v, i) => v !== "00" || i > 0)
      .join(":")
  }

  datadehoje(tp) {

    var data = new Date();
    var dia = ('0' + String(data.getDate())).slice(-2);
    var mes = ('0' + String(data.getMonth() + 1)).slice(-2);
    var ano = data.getFullYear();
    if (tp = 'brasuca') {
      return dia + '/' + mes + '/' + ano;
    }
    if (tp = 'americanada') {
      return ano + '-' + mes + '-' + dia;
    }
  }
  validDataFormat(xdData) {
    const arrData = xdData.split('/')
    const data = new Date();
    const aDiaMes = ['01', 31, '02', 29, '03', 31, '04', 30, '05', 31, '06', 30, '07', 31, '08', 31, '09', 30, '10', 31, '11', 30, '12', 31]
    const ano = data.getFullYear();

    // valida o ano correto
    if (arrData[2] > ano + 1 || arrData[2] < ano - 1) {
      alert('ANO INVÁLIDO')
      return false
    }

    // valida o dia correto
    for (let xi = 0; xi < aDiaMes.length; xi++) {
      if (aDiaMes[xi] == arrData[1]) {
        if (arrData[0] === '00' || arrData[0] > aDiaMes[xi + 1]) {
          alert('DIA INVÁLIDO')
          return false
        }
      }
    }

    // valida o mês correto
    if (arrData[1] < '01' || arrData[1] > '12') {
      alert('MES INVÁLIDO')
      return false
    }

    if (
      arrData[0].length !== 2 ||
      arrData[1].length !== 2 ||
      arrData[2].length !== 4
    ) {
      alert('Formato Data = dd/mm/aaaa')
      return false
    }
    return true
  }

  atualizarLotes(novosLotes: any[], funcao: Function) {
    const dstUrla = ['10.3.0.49:885'];

    novosLotes.forEach(q => {
      this._http.post(`http://${dstUrla}/atualizaLote`, q).subscribe(q => funcao());
    })
  }

  dividirDiaEmPartes(data, numeroDeDivisoes) {
    const hora = data.getHours();
    let divisaoDaHora = Math.ceil((hora + 1) / numeroDeDivisoes);

    return divisaoDaHora;
  }

  formatarDataParaString(data, dataString = '', querHora = true) {
    if (dataString != '') data = new Date(dataString);
    const dia = String(data.getDate()).padStart(2, '0');
    const mes = String(data.getMonth() + 1).padStart(2, '0');
    const ano = data.getFullYear();
    const horas = String(data.getHours()).padStart(2, '0');
    const minutos = String(data.getMinutes()).padStart(2, '0');
    return `${dia}/${mes}/${ano}` + (querHora ? ` ${horas}:${minutos}` : '');
  }


  formataStatus(str) {
    if (!str.includes('-'))
      return str.replace(' ', '');
    return str.split('-')[1].replace(' ', '');
  }


  confirmDialog(confirmText: string, botoes = ['SIM', 'NÃO']): Observable<boolean> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: { text: confirmText, botoes: botoes },
    })

    return dialogRef.afterClosed();
  }

  acessoUsuario(usuario, acesso) {
    const perfil = usuario.perfil.replace("Qualidade ", "");
    if (usuario.perfil.includes('Administrador')) return true;
    return perfil.includes(acesso) || acesso.includes(perfil);
  }

  converteData(dataAtual: Date) {
    const dia = String(dataAtual.getDate()).padStart(2, '0');
    const mes = String(dataAtual.getMonth() + 1).padStart(2, '0'); // Mês é base 0, então adicionamos +1
    const ano = dataAtual.getFullYear();
    const dataFormatada = `${ano}-${mes}-${dia}`;

    return dataFormatada;
  }

  formatarData(data: string) {
    if (data != undefined) {
      data = data.split('T')[0];
      const partes = data.split('-');

      return `${partes[2].padStart(2, '0')}/${partes[1].padStart(2, '0')}/${partes[0].padStart(2, '0')}`;
    } else return "";
  }

  converterParaDDMMYY(dataString, plus = 0) {
    // Divide a string da data nos componentes dia, mês e ano
    var partes = dataString.split('/');
    // Obtém os componentes da data
    var dia = partes[0];
    var mes = parseInt(partes[1]);
    var ano = parseInt(partes[2]);

    mes = plus != 0 ? (mes + plus) % 12 : mes;
    plus = plus != 0 ? Math.trunc(plus / 12) : 0;
    ano += plus
    // Retorna a data formatada
    return dia + '/' + mes.toString().padStart(2, '0') + '/' + ano.toString().slice(-2);
  }

  exportExcel(fileName, sheetName, data) {
    const fn = fileName + '.xlsx';
    const sn = sheetName;
    const workSheet = XLSX.utils.json_to_sheet(data, { header: [] });
    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, sn);
    XLSX.writeFile(workBook, fn);
  }

  cols(){
    return 2;
  }

  rowHeightCalculator(base){
    const height = window.innerHeight;
    const width = window.innerWidth;

    let retur;
    if (width <= 394)
      retur = height * 0.16 + base;
    else
      retur = height * 0.15 > 139 ? (height * 0.15) + (height * (base/100)) : 139.65 + (height * (base/100)) ;
    return retur;
  }


}
